import { IoFlame, IoLayers, IoRocket, IoTrophy } from 'react-icons/io5'
import '../../styles.css'
//import SubNextButton from '../SubNextButton'
import { useEffect, useState } from 'react'
//import Corner from '../Corner'
import SubModal from '../SubModal'
import api from '../../../../../config/connection/axios'
import SubWritting from './SubWritting'

function SubPremium({ scrollWin, handleSubSubmit, spinner }: { scrollWin?: any, handleSubSubmit: (event: Event, wid: number, price: number) => void, spinner: boolean }) {


  const [subVisible, setSubVisible] = useState<boolean>(false);
  const [works, setWorks] = useState<Array<Object>>([])
  const [selectedWork, setSelectedWork] = useState<{} | any>({});

  useEffect(() => {
    const fetchData = async (uid: string) => {
      if (uid) {
        await api.get(`/cards/belongs/${uid}`)
          .then(r => {
            const result = r.data;
            setWorks(result || [])
            setSelectedWork(result[0] || {})
            //setSpinner(false)
          })
      }
    }

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
    fetchData(u.uid)
  }, [])



  return (
    <div className='SubContentArea' >
      {
        subVisible &&
        <SubModal
          works={works}
          setSelectedWork={setSelectedWork}
          subVisible={subVisible}
          setSubVisible={setSubVisible}
        />
      }
      {/*

          DESCOMENTAR ESSE BLOCO!


        !!scrollWin &&
        <SubNextButton
          scrollWin={scrollWin}
          text='Destaque'
          icon={<IoTrophy />}
        />
    */}
      <SubWritting
        handleSubSubmit={handleSubSubmit}
        spinner={spinner}

        selectedWork={selectedWork}
        setSubVisible={setSubVisible}

        swList={[
          {
            icon: <IoFlame />,
            text: 'Você no topo das pesquisas'
          },
          {

            icon: <IoTrophy />,
            text: 'Seus anúncios ficam entre os primeiros'
          },
          {
            icon: <IoRocket />,
            text: 'Se destaque da concorrência'
          }/*,
          {
            icon: <IoLayers />,
            text: 'Anúncios ilimitados'
        }*/
        ]}
        swWorkTitle={selectedWork.title}
        swTitle='Perfil Premium'
     
        swPricing={[
          {
            text: ' 6 meses',
            price: ' 22,90 '
          },
          {
            text: ' 12 meses',
            price: ' 29,90 '
          },
          {/*
            text:' 12 meses',
            price:' 89,90 '
        */}
        ]}
        swTextings={[
          {
            title: 'Agora só paga após receber!',
            text:
              <p>O modelo de pagamento após o <strong>contato de dois clientes</strong> é uma abordagem mais vantajosa. Nesse sistema, você não precisa realizar nenhum pagamento antecipado para anunciar seus serviços com destaque.</p>
          },
          {
            title: 'A ideia por trás desse novo modelo é simples:',
            text: <p> Você cria seus anúncios destacados gratuitamente e só precisa pagar <strong>após ser contatado por pelo menos dois clientes interessados</strong> em seus serviços.<br /><br /> Isso significa que você <strong>só investe financeiramente quando já está começando a obter resultados reais</strong>, garantindo um retorno sobre o investimento.</p>
          }
        ]}
      />
    </div>
  )
}

export default SubPremium;
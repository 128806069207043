import {
    IoAdd,
    IoHome,
    IoHomeOutline,

    IoList,
    IoListOutline,

    IoPersonCircle,
    IoPersonCircleOutline,

    IoSearch,
    IoSearchOutline
} from "react-icons/io5"
import { Link, useLocation } from "react-router-dom"

import './styles.css'

function NavigationBar() {
    const height = '58px'
    const location = useLocation();
    const current = location.pathname;
    const uid = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid;
    //const [noError, setNoError] = useState<boolean>(true);

    return (
        <div
            style={{
                display: navigator.userAgent.match(/expo/i)
                    ? 'flex'
                    : 'none',
                height
            }}
        >
            <div id='NavigationBar'
                style={{ height }}
            >
                <Link to='/'>
                    {(current === '/')
                        ? <IoHome />
                        : <IoHomeOutline />
                    }
                </Link >
                <Link to='/search'>
                    {(current === '/search')
                        ? <IoSearch />
                        : <IoSearchOutline />
                    }
                </Link >

                <Link to={uid ? '/work/create' : '/login'}>
                    <span className="nav-add-btn center">
                        <IoAdd />
                    </span>
                </Link >
                <Link to='/categories'>
                    {(current === '/categories')
                        ? <IoList />
                        : <IoListOutline />
                    }
                </Link >
                <PersonOff current={current} uid={uid} />
            </div>
        </div>
    )
}

const PersonOff = ({ current, uid }: { current: string, uid: string }) => (
    <Link
        to={uid ? '/profile' : '/login'}
    >
        {(current === '/profile')
            ? <IoPersonCircle />
            : <IoPersonCircleOutline />
        }
    </Link>
)
/*
const PersonOn = ({ current, uid, setNoError }: { current: string, uid: string, setNoError: any }) => (
    <Link
        to='/profile'
    >
        <img
            style={{ width: '22px', height: '22px', border: `2px solid ${current === '/profile' ? 'black' : 'transparent'}`, borderRadius: '100%' }}
            src={}
            onError={() => setNoError(false)}
        />
    </Link>
)
*/
export default NavigationBar;
import './styles.css'
import { Link } from 'react-router-dom'
import Icon from '../../Icon'
import { FaDollarSign } from 'react-icons/fa'
//import api from '../../../config/connection/axios'

interface Conatctbuttons {
    type?: string
    height?: string,
    data: any,
    setChat: any
}

function ContatButtons({ type = 'onlyButtons', height, data, setChat }: Conatctbuttons) {
    return (
        <div
            id='ContactButtons'
            className={`ContactButtons--${type} center`}
        >
            <div className='CBtns-center'>
                {JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid
                    ? <CbChatButton onClick={setChat} height={height} />
                    : <Link to='/signin' className='CBtns-l-a center'>
                        <CbChatButton />
                    </Link>
                }
            </div>
        </div>
    )
}


const CbChatButton = ({ onClick, height }: { onClick?: any, height?: string, }) => (
    <button
        onClick={onClick && onClick}
        style={{
            height: height && height,
        }}

        className='CBtns-l-a center'
    >
        <div
            style={{
                left: 0,
                top: 0,
                height: '100%',
                backgroundColor: '#00000060',
                width: '19%',
                position: 'absolute'
            }}
            className="center"
        >
            <Icon style={{ marginLeft: '-4px' }}>
                <FaDollarSign size={18} />
            </Icon>
        </div>
        Contratar
    </button>
)

export default ContatButtons
import axios from "axios";

const api = axios.create({
    /* Production */
    baseURL: "https://servicess-server.herokuapp.com"

    /* Development */
    //baseURL: "http://192.168.0.178:8000"
});

export default api;
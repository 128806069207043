import { useState } from "react"
import SubscriptionContainer from "../components/Containers/SubscriptionContainer"
import Footer from "../components/Footer"
import ThinHeader from "../components/Headers/ThinHeader"
import SafeDiv from "../components/SafeDiv";
import api from "../config/connection/axios";


function Subscription() {
  const [linkBuyMercadoPago, setLinkBuyMercadoPago] = useState<string>('')
  const [spinner, setSpinner] = useState<boolean>(false)

  const handleSubSubmit = async (event: Event, wid: number, price: number,) => {
    event.preventDefault()

    setSpinner(true);

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

    await api.post(
      '/pix/generate/payment',
      {
        uid: u.uid, price
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${u.token}`
        }
      }
    )
      .then(r => {
        setSpinner(false);
        const result = r.data;
        setLinkBuyMercadoPago(result.linkBuyMercadoPago)
      })
  }


  return (
    <div>
      <ThinHeader />
      {
        (!linkBuyMercadoPago) &&
        <SubscriptionContainer
          handleSubSubmit={handleSubSubmit}
          spinner={spinner}
        />
      }
      {
        (linkBuyMercadoPago) &&
        <SafeDiv style={{ width: '100vw', height: '1200px' }}>
          <iframe
            src={linkBuyMercadoPago}
            width="100%"
            height='100%'
            title="link_buy"
          />
        </SafeDiv>
      }
      <Footer />
    </div>
  )
}

export default Subscription
import io from 'socket.io-client';
const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

const url = 'https://servicess-server.herokuapp.com'; /* Production */
//const url = 'http://192.168.0.178:8000'; /* Development */

const socket = io(url, {
  query: { token: u.token }
});

export default socket;
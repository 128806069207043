import { IoCheckmark } from 'react-icons/io5'
import DcTitle from './DcTitle'

function E({ checklist }: { checklist: Array<string> }) {
    return (
        <div className='DC-card DC-card-B'>
            <DcTitle
                text='Diferenciais'
            />
            <ul className='DC-check-list'>
                {
                    checklist.map((it, idx) => (
                        <li style={{ display: 'flex', alignItems:'center' }} key={idx}>
                            <span
                                className='center'
                                style={{
                                    padding:'4px',
                                    backgroundColor: '#222d36',
                                    borderRadius:'100%'
                                }}>
                                <IoCheckmark size={14} color='white' />
                            </span>
                            <p style={{ marginLeft: '12px', fontWeight: 500 }}>{it}</p>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default E
import { IoOpen, IoOpenOutline } from 'react-icons/io5';
//import Brokenlist from '../../../BrokenList';
import DcTitle from './DcTitle';

interface ID {
  state: string,
  city: string,
  district: string,
  zipcode: number | string,
  address: string,
  number: number,
}
function D({ state, city, district, zipcode, address, number }: ID) {

  const googleApi = `http://maps.google.com/?q=${address}, ${number}, ${district}, ${city}, ${state}`

  return (
    <div className='DC-card DC-card-D'>
      <DcTitle
        noBottom={true}
        text='Localização'
      />
      <div className='dc-d-divider'>
        <a href={googleApi} target='_blank' className='DC-card-D-map'>
          <div
            className='center'
            style={{
              position: 'absolute',
              width: '100%',
              marginTop: '30%'

            }}
          >
            <img
              style={{
                width: '56px',
                height: '70px',
              }}
              src={require(`../../../../assets/location.png`)}
              alt='Icone de localização'
            />
          </div>
          <div
            className='dc-open-map-wrapper'
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent'
            }}>
            <div className='DC-open-map center'>
              Ver no mapa
              <span className='center'>
                <IoOpenOutline />
              </span>

            </div>
          </div>
          <img src={require(`../../../../assets/mapby${window.innerWidth > 960 ? '' : '-large'}.png`)} alt='Mapa simples cor cinza' />
        </a>
        <div className='D-content-wrapper'>
          <div className='DC-card-D-content'>
            <ul>
              {
                state && (
                  <li>
                    <p>Estado</p>
                    <h4>{state}</h4>
                  </li>
                )
              }
              {
                city && (
                  <li>
                    <p>Cidade</p>
                    <h4>{city}</h4>
                  </li>
                )
              }
              {
                district && (
                  <li>
                    <p>Bairro</p>
                    <h4>{district}</h4>
                  </li>
                )
              }
              {
                (zipcode || address) &&
                <DcSpacer />
              }
              {
                zipcode && (
                  <li>
                    <p>CEP</p>
                    <h4>{zipcode}</h4>
                  </li>
                )
              }
              {
                address && (
                  <li>
                    <p>Endereço</p>
                    <h4>
                      {address.trim()}{number ? `, ${number}` : ''}
                    </h4>
                  </li>
                )
              }
            </ul>
            {/*<Brokenlist />*/}
          </div>
        </div>
      </div>
    </div >
  );
}


const DcSpacer = () => (
  <div
    style={{
      width: '80%',
      marginLeft: '5%',
      height: '1px',
      borderTop: '1px solid #DEE6EB',
      paddingBottom: '20px',
    }}
  />
)
export default D
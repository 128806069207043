
import { useEffect, useRef, useState } from 'react';
import { IoSend } from 'react-icons/io5';
import '../styles.css';

import Chat_ from '../../chat-interfaces/chat.interface';
import ChatBubble from '../ChatBubble';
import ChatWelcome from '../ChatWelcome';
import ChatAutoMessage from '../ChatAutoMasage';
import ChatWelcomeResponse from '../ChatWelcomeResponse';
import formatNewTime from '../../../functions/formatNewTime';
import { screenName_ } from '../../chat-types/screenName.types';

interface letter_ {
    text: string,
    author: string,
    time: string
    date: number,

}

export interface conversation_ {
    _id: string,
    users: [idx0: string, idx1: string],
    messages: Array<letter_>,
}

function Chatting(
    {
        socket,
        uid,
        receiver,
        name,
        //profession,
        handleScreen
    }:
        {
            socket: any,
            uid: string
            receiver: string | null | undefined,
            name: string | null | undefined,
            //profession: string
            handleScreen: (r: Chat_['simpleReceiver'] | null, sn: screenName_) => void
        }
) {

    const chatRef = useRef(null);

    const [room, setRoom] = useState<string | boolean>(false);
    const [chat, setChat] = useState<Array<letter_>>([]); //messages
    const [message, setMessage] = useState('');


    const pushing = (letter: letter_) => {
        setChat(list => [...list, letter]);

        if (chatRef.current) {
            const chatMain: any = chatRef.current;
            const top = chatMain.scrollHeight + chatMain.scrollTop;
            chatMain.scrollTo({
                top,
                behavior: 'smooth'
            });
        }
    }

    const sendMessage = (e: any) => {
        e.preventDefault()

        if (!message.length) return;
        if (!receiver) return;
        if (!uid) return;

        const letter: letter_ = {
            text: message,
            author: uid,
            time: formatNewTime(),
            date: Date.now()
        }


        setMessage('');
        pushing(letter);

        socket.emit('sendMessage', room, letter)
    }

    useEffect(() => {
        const joinRoom = () => {
            setRoom(true);
            socket.emit('joinRoom', uid, receiver);
            socket.emit('cleanInBox', uid, receiver);
        }

        if (!room) joinRoom();

        socket.on('conversation', (conversation: conversation_) => {
            setRoom(conversation._id)
            setChat(conversation.messages)
        })
        socket.on('receivedMessage', (resp: letter_) => {
            pushing(resp);
        });
    }, [socket]);

    useEffect(() => {
        const leaveRoom = () => {
            setRoom(false);
            socket.emit('leaveRoom', uid, receiver);
        }
        return leaveRoom;
    }, [])

    return (
        <div id='Chatting'>
            {
                (
                    !uid ||
                    !receiver ||
                    !name
                )
                    ? <strong>Missing data</strong>
                    : (
                        <div id='ChatApp'>
                            {/*<div className='chatA-header center'>
                                <span className='chat-back center'>
                                    <span onClick={()=>handleScreen(null, 'list')}>
                                        <IoList size={22} />
                                    </span>
                                </span>
                                <div>
                                    <ChatLi
                                        style='a'
                                        receiver={receiver}
                                        name={name}
                                        //profession={profession}
                                    />
                                </div>
                    </div>*/}
                            <div className='chatA-middle center' style={{ height: 'calc(100% - 60px)' }}>
                                {/*<div className='chatA-middle-shadow-top'/>*/}
                                <ul className='chatA-main' ref={chatRef} style={{ padding: 0 }}>
                                    {/*!chat.length && <ChatWelcome />*/}
                                    {/*!chat.length && <ChatWelcomeResponse author={chat.length > 3 ? chat[1].author : ''} />*/}
                                    {/*chat.length ? <ChatAutoMessage text='Conversa iniciada' /> : <></>*/}
                                    {
                                        chat.map((msg: letter_, idx: number) => (
                                            <ChatBubble
                                                key={idx}

                                                uid={uid}
                                                message={msg.text}
                                                author={msg.author}
                                            />
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className='chatA-footer center'>
                                <form>
                                    <input
                                        placeholder='Escreva a sua mensagem...'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    <button onClick={sendMessage}>
                                        <span className='center'>
                                            <IoSend />
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}






export default Chatting;
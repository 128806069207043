import { useState } from "react";
import Spinner from "../../../../Spinner";
import api from "../../../../../config/connection/axios";
import Modal from "../../../../Modal";
import ModalReward from "../../../../ModalReward";
import SimpleDarkButton from "../../../../Buttons/SimpleDarkButton";
//import Spinner from "../../../../Spinner"

function SubWritting(
    {
        swList,
        swImage,
        swTitle,
        swPricing,
        swWorkTitle,
        swTextings,

        selectedWork,
        setSubVisible,
        handleSubSubmit,
        spinner
    }:
        {


            swList: Array<any>,
            swImage?: any,
            swTitle: string,
            swPricing: Array<any>,
            swWorkTitle: any,
            swTextings: any,

            selectedWork: any
            setSubVisible: any,
            handleSubSubmit: any,
            spinner: boolean
        }
) {
    const [radioSelect, setRadioSelect] = useState<0 | 1>(0);
    const [spinnerFree, setSpinnerFree] = useState<boolean>(false);
    const [premiumFreeModal, setPremiumFreeModal] = useState<boolean>(false);
    const [PFFreeModalContent, setPFFreeModalContent] = useState<0 | 1>(0);

    const handleFreeSubSubmit = () => {
        setSpinnerFree(true);
        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
        if (u.uid) api.get(`/premium/free/${u.uid}`)
            .then(() => {
                openPremiumFreeModal(1)

            })
            .catch(() => {
                openPremiumFreeModal(0)
            })
    }

    const openPremiumFreeModal = (pfcontent: 0 | 1) => {
        setSpinnerFree(false);
        setPremiumFreeModal(true);
        setPFFreeModalContent(pfcontent);
    }

    return (
        <>
            <div className='SubPremium-left' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Modal
                    visible={premiumFreeModal}
                    setVisible={setPremiumFreeModal}
                    children={<ModalReward type="already_free_premium" />}
                />
                <div className='SubPremium-left-main sp-main-left-w'>
                    {
                        swTextings.map((it: any, idx: number) => (
                            <div style={{ color: 'var(--dark)', fontSize: '16px', marginTop: '30px', lineHeight: '1.5' }}>
                                <strong
                                    style={{
                                        fontWeight: 100,
                                        fontSize: '28px',
                                        margin: '10px 0',

                                    }}
                                >
                                    {it.title}
                                </strong>
                                {it.text}
                            </div>
                        ))
                    }
                </div>
                <div className='sp-main-left-w' style={{ marginTop: '40px' }}>
                    <strong
                        style={{
                            fontWeight: 100,
                            fontSize: '28px',
                            margin: '10px 0',

                        }}
                    >
                        Vantagens:
                    </strong>
                    <ul className='SubPremium-grid'>
                        {
                            swList.map((it, idx) =>
                                <SubGridLi
                                    icon={it.icon}
                                    text={it.text}
                                />
                            )
                        }
                    </ul>
                </div>
                <div className='SubDisplayNone' style={{ height: '100px', width: '80%', marginTop: '18px' }}>
                    {swImage && swImage}
                </div>
            </div>
            <div className='SubPremium-right center'>
                <div
                    className='SubPremium-border center column'
                >
                    <div className='SubPremium-border-strong'>
                        <strong>Assinatura de 12 meses</strong>
                    </div>
                    <div style={{ width: '100%' }} className='center column'>
                        <div style={{ width: '90%', display: 'flex' }}>
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: '#eee',
                                    borderRadius: '6px 6px 0 0',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    padding: '16px 20px 10px 20px',
                                    alignItems: 'center',
                                    position: 'relative'
                                }}
                            >
                                {/*
                                    (selectedWork.id) &&
                                    <h6 style={{ whiteSpace: 'nowrap', margin: '0 10px 0 0', backgroundColor: 'dodgerblue', padding: '4px 12px', width: 'fit-content', color: 'white', borderRadius: '100px' }}>
                                        nº {selectedWork.id}
                                    </h6>
                            */}
                                <h5
                                    style={{ margin: 0, opacity: .6 }}
                                    className='ellipsis'
                                >
                                    Escolha como prefere pagar:
                                    {/*
                                        (swWorkTitle)
                                            ? swWorkTitle
                                            : <Spinner visible size={12} color='#222D36' />
                                    */}
                                </h5>
                                <div style={{ zIndex: 1, width: '10px', height: '10px', position: 'absolute', backgroundColor: '#eee', bottom: 0, right: '-10px' }}>
                                    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: '0 0 0 100px' }} />
                                </div>
                            </div>
                            {/*<div style={{ flex: 1 }} className='center'>
                                {
                                    (selectedWork.id) &&
                                    <button
                                        onClick={() => setSubVisible(true)}
                                        style={{
                                            zIndex: 2,
                                            color: 'white',
                                            backgroundColor: '#222D36',
                                            borderRadius: '6px',
                                            width: '82%',
                                            height: '76%'
                                        }}
                                    >
                                        Trocar
                                    </button>
                                }
                            </div>*/}
                        </div>
                        <ul style={{ width: '90%', backgroundColor: '#eee', borderRadius: '0 6px 6px 6px', padding: '30px 0' }} className='center column'>
                            <SubPLi
                                idx={0}
                                radioSelect={radioSelect}
                                setRadioSelect={setRadioSelect}
                                swPricing={swPricing}
                            />
                            <SubPLi
                                idx={1}
                                radioSelect={radioSelect}
                                setRadioSelect={setRadioSelect}
                                swPricing={swPricing}
                            />
                            {/* <SubPLi
                                idx={2}
                                radioSelect={radioSelect}
                                setRadioSelect={setRadioSelect}
                                swPricing={swPricing}
                            />*/}
                        </ul>
                    </div>
                    <div style={{ width: '100%', height: '90px' }} className='center'>

                        <SimpleDarkButton
                            onClick={
                                (e: Event | any) => {
                                    radioSelect === 0
                                        ? handleFreeSubSubmit()
                                        : handleSubSubmit(e, selectedWork.id, radioSelect)
                                }
                            }
                            disabled={spinner}
                        >
                            {(!spinner || !spinnerFree) ? 'Comprar' : <Spinner visible={spinner} />}
                        </SimpleDarkButton>
                    </div>
                </div>
            </div>
        </>
    )
}

const SubPLi = ({ idx, radioSelect, setRadioSelect, swPricing }: { idx: number, radioSelect: number, setRadioSelect: any, swPricing: Array<any> }) => (
    <li
        onClick={() => setRadioSelect(idx)}
        style={{
            width: 'calc(84% - 10px)',
            height: '78px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '12px',
            paddingLeft: '10px',
            marginBottom: '20px',
            border: radioSelect === idx ? '2px solid dodgerblue' : '2px solid transparent',
            cursor: 'pointer',
            backgroundColor: radioSelect === idx ? 'white' : 'transparent',

        }}
    >
        <input
            type="radio"
            name="radioSelect"
            value='radioSelect'
            checked={radioSelect === idx}
            style={{ height: '20px', width: '20px' }}

        />
        <div style={{ marginLeft: '20px' }}>
            <p style={{ opacity: .99, fontSize: '16px' }}>
                {/*
                Assinatura de
                
                idx === 0 && swPricing[0].text
                idx === 1 && swPricing[1].text
                idx === 2 && swPricing[2].text
                */}
                {idx === 0 && 'Pagar depois'}
                {idx === 1 && 'Pagar agora'}
            </p>
            <div style={{ height: '6px' }} />
            <strong style={{ fontSize: '15px' }}>
                {idx === 0 && 'Grátis'}
                {idx === 1 && 'R$ 19,90'}
                {/*
                R$
                idx === 0 && swPricing[0].price
                idx === 1 && swPricing[1].price
                {/*idx === 2 && swPricing[2].price
                */}
            </strong>
            {idx === 1 && <strong style={{ color: '#69696990', textDecoration: 'line-through', fontSize: '12px' }}>&nbsp;&nbsp;29,90&nbsp;&nbsp;</strong>}
        </div>
    </li>
)


const SubGridLi = ({ icon, text }: { icon: any, text: string }) => (
    <li style={{ display: 'flex', fontSize: '30px', color: '#222D36', alignItems: 'center' }} >
        <span className='center' style={{ marginRight: '10px' }}>
            {icon}
        </span>
        <strong style={{ fontSize: '16px' }}>
            {text}
        </strong>
    </li>
)

export default SubWritting